<template>
  <div class="blog-form blog-admin-form">
    <div class="js-blog-scrollbar">
      <vue-scroll :ops="ops">
         <Spin :spinning="isFetching">
          <div class="blog-admin-form__title" @click="goBack">
            <SvgIcon name="down-arrow" class="blog-admin-form__arrow-back"/>
            <span>article editing</span>
          </div>
          <div class="form blog-form__form">
            <div class="blog-form__label blog-form__headline">
              <div class="blog-form__caption">Headline</div>
              <div class="blog-form__field-wrapper">
                <input
                  class="blog-form__field"
                  type="text"
                  autocomplete="off"
                  placeholder="Enter here"
                  v-model="article.title"
                />
              </div>
            </div>
            <div class="blog-form__label blog-form__writer">
              <div class="blog-form__caption">By-line (writer)</div>
              <div class="blog-form__field-wrapper">
                <input
                  class="blog-form__field"
                  type="text"
                  autocomplete="off"
                  placeholder="Enter here"
                  v-model="article.author"
                />
              </div>
            </div>
            <div class="blog-form__label blog-form__date">
              <div class="blog-form__caption">Date</div>
              <div class="blog-form__field-wrapper js-datepicker-wrap">
                <a-date-picker
                  format="MM-DD-YYYY"
                  placeholder="Select date"
                  :showToday="false"
                  v-model="article.publishedAt"
                />
              </div>
            </div>
            <div class="blog-form__label blog-form__subheading">
              <div class="blog-form__caption">Subheading</div>
              <div class="blog-form__field-wrapper">
                <input
                  class="blog-form__field"
                  type="text"
                  autocomplete="off"
                  placeholder="Enter here"
                  v-model="article.subheading"
                  />
              </div>
            </div>
            <div class="blog-form__label blog-form__position">
              <div class="blog-form__caption">author's position</div>
              <div class="blog-form__field-wrapper">
                <input
                  class="blog-form__field"
                  type="text"
                  autocomplete="off"
                  placeholder="Enter here"
                  v-model="article.author_position"
                  />
              </div>
            </div>
            <div class="blog-form__label blog-form__text">
              <div class="blog-form__caption">Article text</div>
              <div class="blog-form__field-wrapper">
                <Spin :spinning="isUploadImage">
                  <VueEditor
                    id="editor"
                    useCustomImageHandler
                    @image-added="handleImageAdded"
                    :editor-toolbar="['bold', 'italic', 'underline', 'image']"
                    v-model="article.content"
                  />
                </Spin>

              </div>
            </div>
            <div class="blog-form__label blog-form__categories">
              <div class="blog-form__caption">Add category / categories</div>
              <div class="blog-form__field-wrapper">
                <label class="blog-form__checkbox-wrapper" :data-cheked="chekedCategories.includes(category.id)" :for="category.id" v-for="category in categories" :key="category.id">
                    <input class="blog-form__field" type="checkbox" :id="category.id" :value="category.id" v-model="chekedCategories" autocomplete="off" />
                    <span>{{ category.attributes.name }}</span>
                </label>
              </div>
            </div>
            <div class="blog-form__label blog-form__subcategories">
              <div class="blog-form__caption">Add sub-category / sub-categories</div>
              <div class="blog-form__field-wrapper">
                <label class="blog-form__checkbox-wrapper" :data-cheked="chekedSubcategories.includes(subcategory.id)" v-for="subcategory in subcategories" :key="subcategory.id">
                  <input class="blog-form__field" type="checkbox" autocomplete="off" :id="subcategory.id" :value="subcategory.id" v-model="chekedSubcategories" />
                  <span>{{ subcategory.attributes.name }}</span>
                </label>
              </div>
            </div>
            <!-- <label class="blog-form__label blog-form__expert">
              <div class="blog-form__caption">Excerpt (to include on front page)</div>
              <div class="blog-form__field-wrapper">
                <textarea class="blog-form__field" rows="4" v-model="article.expert"></textarea>
              </div>
            </label> -->
            <!-- <label class="blog-form__label blog-form__pingbacks">
              <div class="blog-form__caption">Add pingbacks and trackbacks</div>
              <div class="blog-form__field-wrapper">
                <input class="blog-form__field" type="text" autocomplete="off" placeholder="Enter here"/>
              </div>
            </label> -->
            <label class="blog-form__label blog-form__tags">
              <div class="blog-form__caption">Add tags</div>
              <div class="blog-form__field-wrapper blog-form__field-tags">
                <div v-if="tags">
                  <div class="blog-form__tag" v-for="tag of article.tags" :key="tag">
                    <span>{{ tag.attributes.name }}</span>
                    <SvgIcon name="close" />
                  </div>
                </div>
                <input class="blog-form__field" type="text" autocomplete="off" placeholder="Enter here" @keypress="onAddTag"/>
              </div>
            </label>
            <div></div>
            <div class="blog-form__buttons">
              <div class="blog-form__buttons-save">
                <button class="button buttonBlue blog-form__button" @click="saveArticle">Save article</button>
              </div>
              <button class="button buttonBlack" @click="confirmDelete">Delete</button>
            </div>
          </div>
        </Spin>
      </vue-scroll>
    </div>
    <QuestionPopup
      :name="'deleteBlogArticle'"
      :title="'Article will be removed'"
      :text="'Are you sure?'"
      @remove="deleteArticle()"
    />
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import { VueEditor } from 'vue2-editor';
  import { Spin } from 'ant-design-vue';
  import * as MarkdownIt from 'markdown-it'
  import { adminBlogsAPI } from '@/api/admin/blog'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'
  import './Article.styl';

  export default {
    name: 'Article',
    data: () => ({
      dateForm: '',
      ops: {
        rail: {
          size: '4px',
        },
        bar: {
          size: '4px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      article: {},
      chekedCategories: [],
      chekedSubcategories: [],
      tags: [],
      isUploadImage: false,
    }),
    methods: {
      saveArticle () {
        this.$store.dispatch('updateAdminArticle', {
          id: this.$route.params.id,
          article: {
            ...this.article,
            categories: this.chekedCategories,
            subcategories: this.chekedSubcategories,
            // tags: this.tags.join(','),
          },
        })
      },
      confirmDelete () {
        this.$modal.show('deleteBlogArticle')
      },
      async deleteArticle () {
        await this.$store.dispatch('deleteArticle', this.$route.params.id);
        this.$router.push('/fly-report/articles/')
      },
      onAddTag (value) {
        if (value.key === 'Enter') {
          this.tags.push(value.target.value)
          value.target.value = ''
        }
      },
      handleImageAdded (file, Editor, cursorLocation, resetUploader) {
        this.isUploadImage = true;
        adminBlogsAPI.uploadFile(file, this.$store.state.blogDashboard.token)
          .then(result => {
            const url = result.data[0].url; // Get url from response
            Editor.insertEmbed(cursorLocation, 'image', url);
            resetUploader();
            this.isUploadImage = false;
          })
          .catch(err => {
            console.log(err);
            this.isUploadImage = false;
          });
      },
      goBack () {
        this.$router.go(-1);
      },
    },
    computed: {
      subcategories () {
        return this.$store.state.blogDashboard.subcategories;
      },
      categories () {
        return this.$store.state.blogDashboard.categories;
      },
      isFetching () {
        return this.$store.state.blogDashboard.isFetching;
      },
    },
    async mounted () {
      this.$store.dispatch('getAdminSubcategories');
      this.$store.dispatch('getAdminCategories');
      const data = await this.$store.dispatch('getAdminArticle', this.$route.params.id)

      const content = MarkdownIt({ html: true }).render(data.data.attributes.content)

      this.article = { ...data.data.attributes, content }

      this.chekedCategories = data.data.attributes.categories.data.map(({id}) => id);
      this.chekedSubcategories = data.data.attributes.subcategories.data.map(({id}) => id);

      // this.uploader();
    },
    components: {
      SvgIcon,
      VueEditor,
      Spin,
      QuestionPopup,
    },
  };
</script>
